import { useSnapshot } from '@framework/hooks';
import { RTDBPath, useObjectRepository } from '@framework/repository';
import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTimer } from 'react-timer-hook';
import { Timestamp } from '@framework/Timestamp';
import { useSound } from 'use-sound';
import timerCompletedSound from '@assets/timerCompletedSound.mp3';
import { TimerEntity } from '@view-model/domain/view-model';
import { ViewModelId } from '@schema-common/base';
import { useUserType } from '@framework/auth';
import { toast } from 'react-hot-toast';
const MAX_SECONDS = 99 * 60 + 59;

// クライアントの現在時刻を取得
const getClientNow = (): Timestamp => Timestamp.now();

// サーバーとのタイムオフセットを考慮した現在時刻を取得
const getNowWithOffset = (timestampOffset: number | null): Timestamp =>
    getClientNow().addMilliSeconds(timestampOffset || 0);

type ReturnValues = {
    totalSeconds: number;
    isExpired: boolean;
    isRunning: boolean;
    handleControlTimer(): void;
    handleResetTimer(): void;
    handleIncreaseTime(): void;
    handleDecreaseTime(): void;
    handleUpdateTime(seconds: number): void;
    handleResetExpiredState(): void;
    restartForTest(timestamp: Timestamp): void;
};

// タイマーのカウントダウンや状態管理を行うためのカスタムフック
// 同一のビューモデルページを見ているユーザーに対して、共通のタイマーを表示し、開始や一時停止、リセットなどの操作は各ユーザーに反映する必要がある
// その一方で、実際の秒数までユーザー間で同期すると、RTDBを毎秒更新する必要が出てきてしまう
// そのため、
//   1. RTDBではタイマーの状態を管理(進行中や一時停止中、あるいはタイマー開始時に設定された総残秒数など)
//   2. クライアント側ではタイマーの残り時間の漸減やタイマーの状態更新トリガーを管理
// という仕組みを採用している
export const useTimeControl = (viewModelId: ViewModelId, onOpenTimer: () => void): ReturnValues => {
    const [isExpired, setIsExpired] = useState(false);
    const userType = useUserType();
    const isAnonymousUser = useMemo(() => userType === 'anonymous', [userType]);
    const [timer, setTimer] = useState<TimerEntity | null>(null);
    const repo = useObjectRepository(TimerEntity, RTDBPath.ViewModel.timerPath(viewModelId));
    const [playSound, { stop: stopSound }] = useSound(timerCompletedSound, { interrupt: true });

    const onExpireEffect = useCallback(() => {
        onOpenTimer();
        setIsExpired(true);
    }, [onOpenTimer]);

    const {
        totalSeconds,
        pause,
        isRunning: isClientSideCountDownRunning,
        restart,
    } = useTimer({
        expiryTimestamp: getClientNow().toDate(),
        onExpire: () => {
            onExpireEffect();
            if (timer && !isAnonymousUser) {
                repo.save(timer.setCompleted()).then();
            }
        },
        autoStart: false,
    });

    // クライアント側でのタイマーのカウントダウンが実行中かどうかを管理するためのref
    // 後述のRTDBのタイマー情報を監視するuseEffect内で利用するが、stateのまま利用してしまうと不要なaddEventListenerの張り直しが行われタイマー情報の同期に不整合が生じるため、refに置き換えている
    const isClientSideCountDownRunningRef = useRef(isClientSideCountDownRunning);
    useEffect(() => {
        isClientSideCountDownRunningRef.current = isClientSideCountDownRunning;
    }, [isClientSideCountDownRunning]);

    const [timestampOffset] = useSnapshot<number>({
        path: RTDBPath.Firebase.serverTimeOffsetPath(),
        load: ({ snapshot }) => snapshot.val(),
        onError: () => {
            toast.error('読み込みに失敗しました');
        },
    });

    useEffect(() => {
        // ユーザーが一切操作をしていない中でタイマーが完了した場合、タイマー完了後に何らかの操作をしたタイミングでタイマー完了音が遅れて鳴る場合がある
        // そのため、期限切れと同時にユーザーが操作をしているかどうかをチェックしてからタイマー完了音を鳴らす
        if (isExpired && window.navigator.userActivation.hasBeenActive) playSound();
    }, [isExpired, playSound]);

    useEffect(() => {
        repo.addListener((timer) => {
            setTimer(timer);
            const isClientSideCountDownRunning = isClientSideCountDownRunningRef.current;

            if (!timer) {
                // タイマーが登録されていない場合はタイマーを初期化
                setIsExpired(false);
                restart(getClientNow().toDate(), false);
            } else if (timer.isCompleted()) {
                // タイマーが完了している場合はタイマーを初期化
                restart(getClientNow().toDate(), false);

                // クライアント側のタイマーのカウントダウンが完了するよりも僅かに早くRTDB側のタイマー情報が完了状態となってしまうことがある
                // そのため、クライアント側のタイマーが実行中にRTDB側のタイマーが完了状態となっている場合は、タイマー完了状態へ移行する
                if (isClientSideCountDownRunning) {
                    onExpireEffect();
                }
            } else if (timer.isPaused()) {
                // タイマーが一時停止中の場合
                onOpenTimer();
                restart(getClientNow().addSeconds(timer.remainingDuration).toDate(), false);
            } else if (timer.isRunning()) {
                onOpenTimer();
                setIsExpired(false);

                // タイマーが実行中の場合、タイマーを切り忘れたままビューモデルを離れるパターンがあるため、タイマーの期限切れをチェックする
                if (getNowWithOffset(timestampOffset).isAfter(timer.expireAt)) {
                    // 期限切れの場合はタイマーが完了したとみなして保存
                    if (!isAnonymousUser) repo.save(timer.setCompleted()).then();
                } else {
                    // 期限切れでない場合は、サーバーとのタイムオフセットを考慮したクライアントの現在時刻と
                    // 登録されているタイマーの期限切れ時刻との差分を計算し、その差分をクライアントの未補正の現在時刻に足し込む
                    const nowWithOffset = getNowWithOffset(timestampOffset);
                    const diffMilliSeconds = timer.expireAt.diffMilliSeconds(nowWithOffset);
                    const newExpiryTimestamp = getClientNow().addMilliSeconds(diffMilliSeconds);
                    restart(newExpiryTimestamp.toDate(), true);
                }
            }
        });

        return () => {
            repo.removeListener();
        };
    }, [
        viewModelId,
        timestampOffset,
        repo,
        restart,
        onOpenTimer,
        isAnonymousUser,
        isClientSideCountDownRunningRef,
        onExpireEffect,
    ]);

    const handleControlTimer = () => {
        if (isAnonymousUser) return;

        if (isExpired) {
            // タイマーが期限切れの場合、初期状態に戻す
            setIsExpired(false);
            restart(
                // ここはあくまでクライアント側の秒数を調整するためのもので、サーバー側の秒数は考慮しない
                getClientNow()
                    .addSeconds(timer?.initialDuration || 0)
                    .toDate(),
                false
            );
            stopSound();
            if (timer) {
                repo.save(timer.setCompleted()).then();
            }
        } else if (timer?.isRunning()) {
            // 実行中の場合は一時停止
            pause();
            repo.save(timer.setPaused(totalSeconds)).then();
        } else if (timer?.isPaused()) {
            // 停止中の場合は再開
            const newExpiryTimestamp = getNowWithOffset(timestampOffset).addSeconds(totalSeconds);

            restart(newExpiryTimestamp.toDate(), true);
            repo.save(timer?.setRunning(newExpiryTimestamp)).then();
        } else {
            // タイマー新規登録時
            const newExpiryTimestamp = getNowWithOffset(timestampOffset).addSeconds(totalSeconds);
            restart(newExpiryTimestamp.toDate(), true);
            repo.save(
                new TimerEntity({
                    expireAt: newExpiryTimestamp,
                    initialDuration: totalSeconds,
                    currentState: 'running',
                })
            ).then();
        }
    };

    const handleResetTimer = useCallback(() => {
        if (timer?.isRunning() || isAnonymousUser) return;

        setIsExpired(false);
        // ここはあくまでクライアント側の秒数を調整するためのもので、サーバー側の秒数は考慮しない
        restart(getClientNow().toDate(), false);
        stopSound();
        repo.delete().then();
    }, [restart, repo, timer, stopSound, isAnonymousUser]);

    const handleIncreaseTime = () => {
        if (timer?.isRunning() || isAnonymousUser) return;

        // 30秒ごとにキリよく増加させるための秒数を計算
        // ただし、15秒などの半端な場合はキリが良いところまで増加
        // 例 1分15秒→1分30秒 １分45秒→２分00秒
        const adjustedSeconds = Math.min(
            MAX_SECONDS,
            totalSeconds % 30 === 0 ? totalSeconds + 30 : Math.ceil(totalSeconds / 30) * 30
        );

        // ここはあくまでクライアント側の秒数を調整するためのもので、サーバー側の秒数は考慮しない
        const newExpiryTimestamp = getClientNow().addSeconds(adjustedSeconds);
        restart(newExpiryTimestamp.toDate(), false);
    };

    const handleDecreaseTime = () => {
        if (timer?.isRunning() || isAnonymousUser) return;

        // 30秒ごとにキリよく減少させるための秒数を計算
        // ただし、15秒などの半端な場合はキリが良いところまで減少
        // 例 1分15秒→1分00秒 １分45秒→1分30秒
        const adjustedSeconds =
            totalSeconds % 30 === 0 ? Math.max(0, totalSeconds - 30) : Math.floor(totalSeconds / 30) * 30;

        // ここはあくまでクライアント側の秒数を調整するためのもので、サーバー側の秒数は考慮しない
        const newExpiryTimestamp = getClientNow().addSeconds(adjustedSeconds);
        restart(newExpiryTimestamp.toDate(), false);
    };

    const handleUpdateTime = useCallback(
        (newTotalSeconds: number) => {
            if (isAnonymousUser) return;

            // ここはあくまでクライアント側の秒数を調整するためのもので、サーバー側の秒数は考慮しない
            const newExpiryTimestamp = getClientNow().addSeconds(newTotalSeconds);
            restart(newExpiryTimestamp.toDate(), false);
        },
        [restart, isAnonymousUser]
    );

    const handleResetExpiredState = useCallback(() => {
        setIsExpired(false);
        stopSound();
    }, [stopSound]);

    // テスト時に期限を設定するための関数
    const restartForTest = (timestamp: Timestamp) => {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('restartForTest はテスト環境でのみ利用できます。');
        }
        restart(timestamp.toDate(), false);
    };

    return {
        totalSeconds,
        isExpired,
        isRunning: timer?.isRunning() || false,
        handleControlTimer,
        handleResetTimer,
        handleIncreaseTime,
        handleDecreaseTime,
        handleUpdateTime,
        handleResetExpiredState,
        restartForTest,
    };
};
